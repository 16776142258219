@import '../variables';

.Header {
  height: 73px;
  min-height: 73px;
  padding: 1rem;

  @include media-breakpoint-down(sm) {
    padding: 0.5rem 0 0.5rem 0;
  }

  &__logo {
    height: 4.25rem;
    position: absolute;
    top: 10px;
    background-color: $white;

    @include media-breakpoint-down(sm) {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  &__link {
    padding: $btn-padding-y $btn-padding-x;
    color: $gray-900;

    &:hover {
      background: $gray-100;
      color: $gray-900;
    }

    &.active {
      color: $primary;
    }

    &--border-right {
      border-right: 1px solid $gray-200;

      @include media-breakpoint-down(sm) {
        border: 0;
      }
    }
  }
}
