@import '../variables';

.CookieBanner {
  position: fixed;
  background: $white;
  padding: map_get($spacers, 4);
  z-index: 1000;
  box-shadow: $box-shadow-lg;
  width: 50%;
  max-width: 500px;
  right: 1rem;
  bottom: 1rem;
  animation-name: fade-in-up;
  animation-duration: 0.75s;
  animation-fill-mode: both;

  @include media-breakpoint-down(sm) {
    // 61px = computed height of Navigation.tsx on mobile devices
    bottom: calc(61px + 1rem);
  }

  @include media-breakpoint-down(md) {
    left: 1rem;
    width: auto;
    max-width: 100%;
  }

  // This is not used at the moment due to the early out in the component
  &--accepted {
    animation-name: fade-out-down;
  }
}

@keyframes fade-in-up {
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fade-out-down {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
