// Colors
$primary: #5bbebf;
$secondary: #4d8ac4;

// DIN A4 Portrait
$din-a4-width: 21cm;
$din-a4-height: 29.7cm;

// Fonts
$font-family-base: Lato, Sans-Serif;
$headings-font-family: Lato, Sans-Serif;
$headings-font-weight: normal;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.05;
$h6-font-size: $font-size-base;

// Forms
$label-margin-bottom: 0;
$input-focus-border-color: $primary;
$input-box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
$form-feedback-valid-color: #58cc8c;
$form-feedback-invalid-color: #f2695c;

// Buttons
$btn-border-radius-lg: 5px;
$btn-padding-x-lg: 1.5rem;

// Links
$link-color: $secondary;
$link-decoration: none;
$link-hover-decoration: none;

// List inline
$list-inline-padding: 1.5rem;

// Jumbotron
$jumbotron-bg: none;

// Images
$thumbnail-border-radius: 0;
$thumbnail-padding: 0;

// Cards
$card-border-radius: 0;

// Shadows
$box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

// Dropdown
$enable-caret: false;
$dropdown-box-shadow: $box-shadow;

// Modal
$modal-fade-transform: scale(0.8);
$modal-content-box-shadow-sm-up: $box-shadow;
$modal-content-box-shadow-xs: $box-shadow;
$modal-content-border-color: transparent;

// Spacing
$enable-negative-margins: true;

@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/variables';
