@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap';

$utilities: map-merge(
  $utilities,
  (
    'visibility': (
      property: visibility,
      class: visibility,
      responsive: true,
      values: visible hidden,
    ),
  )
);

@import 'bootstrap/scss/utilities/api';

/* Utility classes Bootstrap is sadly missing */
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-zoom-in {
  cursor: zoom-in !important;
}

.text-inherit {
  color: inherit !important;
}

.bg-image-none {
  background-image: none !important;
}

.bg-primary-light {
  background: rgba(var(--bs-primary), 0.2);
}

.w-0 {
  width: 0 !important;
}

/* Provides standardized icon sizes for SVGs in buttons */
.btn svg:not(.Loader) {
  width: 1rem;
  height: 1rem;
}

.btn-lg svg:not(.Loader) {
  width: 24px;
  height: 24px;
}

/* Makes buttons look like a circle */
.btn-circled {
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
}

.btn-circled .btn-lg {
  width: 45px;
  height: 45px;
  border-radius: 50% !important;
}

/* Changes autocomplete styles in Chrome */
/* Source: https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/ */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

/* Appends optional hint to all non required input field labels */
label[aria-required='false']:after {
  content: 'optional';
  color: var(--bs-gray-500);
  margin-left: 6px;
  font-size: 14px;
}
