@import 'fonts';
@import 'variables';
@import 'extensions';
@import 'animations';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
}

a:focus {
  outline: 0;
  box-shadow: $btn-focus-box-shadow;
}

.headline {
  font-size: 3rem;
  line-height: 1.1;

  @include media-breakpoint-down(sm) {
    font-size: 2rem;
  }
}

.sub-headline {
  font-size: 1.8rem;
  line-height: 1.2;
}

.half-section-background {
  background: linear-gradient(to bottom, $light, $light 50%, $white 50%, $white);

  @include media-breakpoint-down(sm) {
    background: none;

    > div {
      background: $light;
    }

    > div:last-child {
      background: none;
    }
  }
}

// Offset the footer due to <Navigation />
footer {
  @include media-breakpoint-down(sm) {
    // Computed height of <Navigation />
    padding-bottom: 61px;
  }
}

// <Navigation /> is hidden when a Modal is open
body.modal-open footer {
  padding-bottom: 0;
}
