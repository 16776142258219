@import '../variables';

.Navigation {
  display: none;
  box-shadow: $box-shadow-lg;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  z-index: 3000;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  @include media-breakpoint-down(sm) {
    display: flex;
  }

  a {
    padding: map_get($spacers, 2) 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 3);
    text-decoration: none;
    font-size: $font-size-sm;
    color: $gray-700;

    &.active {
      color: $body-color;

      svg {
        color: $primary;
      }
    }

    svg {
      height: 1.5rem;
      color: $gray-500;
    }
  }
}

// Mobile navigation should be hidden when a Modal is open
body.modal-open .Navigation {
  display: none;
}
