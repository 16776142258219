@font-face {
  font-family: 'Lato';
  src: url(assets/fonts/LatoLatin-Light.woff2) format('woff2');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: 'Lato';
  src: url(assets/fonts/LatoLatin-Regular.woff2) format('woff2');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Lato';
  src: url(assets/fonts/LatoLatin-Medium.woff2) format('woff2');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Lato';
  src: url(assets/fonts/LatoLatin-Bold.woff2) format('woff2');
  font-display: swap;
  font-weight: 700;
}
