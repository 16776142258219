.animation-rotate {
  animation: rotate;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes rotate {
  from {
    transform-origin: center;
    transform: rotate(0);
  }

  to {
    transform-origin: center;
    transform: rotate(360deg);
  }
}

.animation-fade-in {
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
