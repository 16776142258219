@import '../variables';

.ScreenLoader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__svg {
    height: 4rem;
    width: 4rem;
    stroke: $primary;
  }
}
